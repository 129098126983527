export const environment = {
  production: true,
  staging: false,
  apiEndpoint: 'https://api.services4eo.com/request',
  authority: 'https://triple-a.services4eo.com',
  clientId: '38d938dc-b9df-4ab9-99c5-abd93300da8c',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: '1a5f44a0-48f1-4a95-9b82-38669c9e51ba',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://store.services4eo.com/'
};
