import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { collapsedClass, ServicesService, SnackbarService, TranslateService } from '@core';
import { BaseLayer, IconOption, Layer, Order } from '@core/models';
import {
  faChevronDown,
  faDownload,
  faInfoCircle,
  faPlay,
  faArrowTurnUp,
  faRectangleList,
  faArrowsToCircle,
  faMagicWandSparkles
} from '@fortawesome/free-solid-svg-icons';
import { take } from 'rxjs/operators';
import {
  isEmptyOrSpaces,
  serializeDateToDateTime,
  serializeToLocaleDate
} from 'shared/helpers';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-checkboxes',
  templateUrl: './checkboxes.component.html',
  styleUrls: ['./checkboxes.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class CheckboxesComponent implements OnInit {
  faPlay = faPlay;
  faDownload = faDownload;
  faChevronDown = faChevronDown;
  faInfoCircle = faInfoCircle;
  faArrowTurnUp = faArrowTurnUp;
  faRectangleList = faRectangleList;
  faArrowsToCircle = faArrowsToCircle;
  faMagicWandSparkles = faMagicWandSparkles;
  serializeDateToDateTime = serializeDateToDateTime;
  serializeToLocaleDate = serializeToLocaleDate;
  isEmptyOrSpaces = isEmptyOrSpaces;

  isCollapsed: boolean[] = [false];
  isOrderCollapsed: {[serviceKey: number]: {[orderKey: number]: boolean}} = {};
  isOutputCollapsed: boolean[] = [false];
  ordersByServiceId: { [key: string]: Order } = {};
  isLoadingOuputs: Map<number, boolean> = new Map();
  collapsedClass = collapsedClass;

  objectKeys = Object.keys;

  env = environment;

  auxOptions: any[] = [];

  orderDetails = [
    { label: 'main-page.orders.order-details.id', acessor: 'id' },
    {
      label: 'main-page.orders.order-details.identifier',
      acessor: 'serviceId'
    },
    {
      label: 'main-page.orders.order-details.created-at',
      acessor: 'createdAt',
      fn: (date: string) => serializeToLocaleDate(date, this.currentLanguage)
    },
    {
      label: 'main-page.orders.order-details.begin',
      acessor: 'beginAt',
      fn: (date: string) => serializeToLocaleDate(date, this.currentLanguage)
    },
    {
      label: 'main-page.orders.order-details.end',
      acessor: 'endAt',
      fn: (date: string) => serializeToLocaleDate(date, this.currentLanguage)
    }
  ];

  filterLayer = [
    'current_SURCOUF/data-store:eastward_sea_water_velocity',
    'oilspill/data-store:Count',
    'wind/data-store:eastward_wind',
    'Tide/data-store:eastward_sea_water_velocity_due_to_barotropic_ocean_tide',
    'wind/data-store:northward_wind',
    'current_SURCOUF/data-store:northward_sea_water_velocity'];

  @Input() options: any[] = [];
  @Input() id!: string;
  @Input() helperText?: string;
  @Input() error: boolean = false;
  @Input() label?: string;
  @Input() currentValue?: string;
  @Input() isLoading: boolean[] = [false];
  @Input() parameterServiceID?: string;
  @Input() parameterOrderID?: string;
  @Input() isFocused?: boolean;

  @Output() checked = new EventEmitter();
  @Output() orderLayerChecked = new EventEmitter();
  @Output() iconClick = new EventEmitter();
  @Output() collapseClick = new EventEmitter();
  @Output() outputDownload = new EventEmitter();
  @Output() bringOnTopClick = new EventEmitter();
  @Output() toggleLegend = new EventEmitter();
  @Output() zoomClicked = new EventEmitter();
  @Output() styleClicked = new EventEmitter();
  @Output() removeFocusClicked = new EventEmitter();

  constructor(
    private translateService: TranslateService,
    private servicesService: ServicesService,
    private snackbarService: SnackbarService
  ) {}

  get currentLanguage() {
    return this.translateService.currentLanguage;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.openParameterServiceAndOrder();
    }, 300);

  }

  onClick(
    event: MouseEvent,
    layerKey: string,
    layers: Layer[],
    isDataTimeSeries: boolean,
    timestamps: string[],
    wmsType: string,
    orderId: number,
    orderIdx: number,
    layerIdx: number,
    jobIdx: number
  ) {
    const complexOrderId = parseInt('' + orderId + orderIdx + layerIdx + jobIdx);
    this.checked.emit({
      event,
      layerKey,
      layers,
      isDataTimeSeries,
      timestamps,
      wmsType,
      complexOrderId,
      orderId
    });
  }

  onLayerInfoClick(layer: BaseLayer) {
    console.log(layer);
  }

  onOrderLayerClick(
    event: MouseEvent,
    order: Order,
    layerName: string,
    wmsParams: L.WMSParams
  ) {
    order.checked = [];
    this.orderLayerChecked.emit({ event, order, layerName, wmsParams });
  }

  onCollapseClick(option: IconOption, index: number) {
    this.isCollapsed[index] = !this.isCollapsed[index];
    option.collapsed = this.isCollapsed[index];
    this.collapseClick.emit({ option, index });
  }

  onIconClick(option: IconOption) {
    this.iconClick.emit(option);
  }

  onDownloadClick(productID: string) {
    this.outputDownload.emit(productID);
  }

  /**
   * Retrieves the jobs and outputs of an order.
   * @param order object of type Order
  */
  onOrderDetails(order: Order): void {  
    if (order.status === 'FINISHED') {
      this.isLoadingOuputs.set(order.id, true);
      this.servicesService.getOrderJobs(order.id).subscribe((jobs) => {
        order.jobs = jobs;
        this.servicesService.getOrderOutputs(order.id).subscribe((outputs) => {
          outputs.forEach((output) => {
            const relatedJob = order.jobs.find((job) => job.id === output.jobId);
            if (relatedJob) {
              if (relatedJob.outputs) {
                relatedJob.outputs.push(output);
              } else {
                relatedJob.outputs = [];
                relatedJob.outputs.push(output);
              } 
              
              if(order.serviceId === 'SITE_RISK_ASSESSMENT__SRA____METOCEAN'){
                this.filterLayer.forEach( key => {
                  const res:any = output.layers;
                  delete res[key];
                })
              }

            }
          });
          this.isLoadingOuputs.set(order.id, false)
        }, (error) => {
          this.displayOutputErrorMessage(error);
          this.isLoadingOuputs.set(order.id, false);
        });
      }, (error) => {
        this.displayOutputErrorMessage(error);
        this.isLoadingOuputs.set(order.id, false);
      });
    } else if (order.status === 'SUBSCRIPTION_ACTIVE') {
      this.isLoadingOuputs.set(order.id, true);
      this.servicesService.getOrderJobs(order.id).subscribe((jobs) => {
        order.jobs = jobs;
        this.servicesService.getOrderOutputs(order.id).subscribe((outputs) => {
          outputs.forEach((output) => {
            const relatedJob = order.jobs.find((job) => job.id === output.jobId);
            if (relatedJob) {
              if (relatedJob.outputs) {
                relatedJob.outputs.push(output);
              } else {
                relatedJob.outputs = [];
                relatedJob.outputs.push(output);
              } 
              
              if(order.serviceId === 'SITE_RISK_ASSESSMENT__SRA____METOCEAN'){
                this.filterLayer.forEach( key => {
                  const res:any = output.layers;
                  delete res[key];
                })
              }

            }
          });
          this.isLoadingOuputs.set(order.id, false)
        }, (error) => {
          if (error.status !== 404)
            this.displayOutputErrorMessage(error);
          this.isLoadingOuputs.set(order.id, false);
        });
      }, (error) => {
        if (error.status !== 404)
          this.displayOutputErrorMessage(error);
        this.isLoadingOuputs.set(order.id, false);
      });
    }

  }

  displayOutputErrorMessage(error: any) {
    let title = this.translateService.translate('main-page.orders.order-details.snackbar.errorTitle');
    let message;
    if (error.status === 500) {
      message = this.translateService.translate('main-page.orders.order-details.snackbar.error500');
    } else if (error.status === 404) {
      message = this.translateService.translate('main-page.orders.order-details.snackbar.error404');
    } else {
      message = this.translateService.translate('main-page.orders.order-details.snackbar.otherError');
    }
    this.snackbarService.danger(title, message).during(5000).show();
  }

  isTimeSeries(type: string) {
    return type.includes('time-series');
  }

  isDataTimeSeries(type: string) {
    return (
      type === 'data-dissemination-time-series' ||
      type === 'data-collection-netcdf-time-series'
    );
  }

  isDisabled(currentValue: string) {
    return currentValue === this.currentValue;
  }

  selectedLabelClass(currentValue: string, index: number) {
    return currentValue === this.currentValue || this.isCollapsed[index]
      ? 'text-secondary font-bold'
      : '';
  }

  statusClass(slug: string) {
    const isFinished = slug === 'FINISHED';

    return isFinished ? 'bg-green-500' : 'bg-yellow-500';
  }
  statusAnimationClass(slug: string) {
    const isFinished = slug === 'FINISHED';
    return {
      'animate-ping bg-yellow-500': !isFinished,
      'bg-green-500': isFinished
    };
  }

  outputLabelClass(isCollapsed: boolean) {
    return { 'text-asset font-bold': isCollapsed };
  }

  isChecked(layerKey: string, orderId: number, orderIdx: number, layerIdx: number, jobIdx: number) {
    const complexOrderId = parseInt('' + orderId + orderIdx + layerIdx + jobIdx);
    return this.servicesService.isLayerChecked(layerKey, complexOrderId) && this.servicesService.isTimeSourceSet(layerKey, complexOrderId);
  }

  onTopClick(layerKey: string, orderId: number, orderIdx: number, layerIdx: number, jobIdx: number) {
    const complexOrderId = parseInt('' + orderId + orderIdx + layerIdx + jobIdx);
    const currentId = `${layerKey}!${complexOrderId}`;
    this.bringOnTopClick.emit(currentId);
  }

  onLegendClicked(layerKey: string, orderId: number, orderIdx: number, layerIdx: number, jobIdx: number) {
    const complexOrderId = parseInt('' + orderId + orderIdx + layerIdx + jobIdx);
    const currentId = `${layerKey}!${complexOrderId}`;
    this.toggleLegend.emit(currentId);
  }

  onZoomClicked(layer: Layer) {
    const bbox = layer.wmsParams.bbox;
    if (bbox) {
      this.zoomClicked.emit(bbox);
    }
  }

  onStyleClicked(layers: Layer[], layerKey: string, orderId: number, orderIdx: number, layerIdx: number, jobIdx: number) {
    const complexOrderId = parseInt('' + orderId + orderIdx + layerIdx + jobIdx);
    const currentId = `${layerKey}!${complexOrderId}`;
    this.styleClicked.emit([layers, currentId, orderId]);
  }

  removeFocus(emitEvent?: boolean) {
    this.options = JSON.parse(JSON.stringify(this.auxOptions));
    this.auxOptions = [];
    this.isCollapsed = this.isCollapsed.map(value => value = false);
    this.isOrderCollapsed = {};
    if (emitEvent) {
      this.removeFocusClicked.emit();
    }
  }

  openParameterServiceAndOrder() {
    if (this.parameterServiceID && this.parameterOrderID) {
      const option = this.options.find((option) => option.identifier === this.parameterServiceID);
      if (option) {
        if (this.isFocused) {
          this.auxOptions = JSON.parse(JSON.stringify(this.options));
          this.options = [option];
        }
        const index = this.options.indexOf(option);
        this.isCollapsed[index] = !this.isCollapsed[index];
        option.collapsed = this.isCollapsed[index];
        this.collapseClick.emit({ option, index });
        this.servicesService.ordersLoaded$.pipe(take(1)).subscribe((loaded) => {
          if (loaded) {
            const order = option.orders.find((order: Order) => order.id === parseInt(this.parameterOrderID ?? '1'));
            const orderIndex = option.orders.indexOf(order);
            this.isOrderCollapsed[index] = {};
            this.isOrderCollapsed[index][orderIndex] = loaded;
            this.onOrderDetails(order);
          } else {
            this.removeFocus();
            this.isCollapsed[index] = false;
          }
        })
      }

    }
  }
}
